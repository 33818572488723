<template>
  <div class="card-container">
    <div class="msg-info-container">
      <img
        class="msg-type-icon"
        :src="msgTypeIcon"
        alt="Message type icon"
        width="150"
      />
      <div class="msg-title">{{ msgData.Content_Data[0].title }}</div>
    </div>
    <div class="msg-sender-container">
      <img
        class="sender-avatar"
        v-if="blobImageUrl"
        :src="blobImageUrl"
        alt="Profile picture"
      />
      <img
        v-else
        class="sender-avatar"
        :src="`https://eu.ui-avatars.com/api/?name=${msgData.Info_Data.Name}&size=250`"
        alt="Profile picture"
        width="150"
      />
      <div class="msg-info">
        <div class="sender-name">{{ msgData.Info_Data.Name }}</div>
      </div>
    </div>

    <div class="card-footer">
      <p
        class="do-not-open-label"
        v-if="msgData.Content_Data[0].ownDeliveryDate == 'true'"
      >
        {{ $t("msgCard.doNotOpenLabel") }}
      </p>
      <button @click="showMessageModal = true">
        {{ $t("msgCard.openMsgButtonLabel") }}
      </button>
      <MessageModal
        :msgContent="msgData"
        :blobImageUrl="blobImageUrl"
        :msgContentLoaded="false"
        v-if="showMessageModal"
        @close="showMessageModal = false"
      ></MessageModal>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MessageData } from "@/types";
import MessageModal from "@/components/MessageModal.vue";
import { PostboxAPI } from "@/api/api";
const postboxAPI = new PostboxAPI();

export default defineComponent({
  name: "MessageCard",
  props: {
    msgData: {
      type: Object as () => MessageData,
      required: true,
    },
  },
  components: { MessageModal },
  data: () => ({
    showMessageModal: false,
    blobImageUrl: null as string | null,
    msgTypeIcon: "",
    msgType: "",
  }),

  created() {
    this.getProfilePic();
    if (this.msgData.Content_Data[0].contenttype == "text/plain") {
      this.msgType = "Text";
    } else {
      this.msgType = this.msgData.Content_Data[0].contenttype;
    }

    this.msgTypeIcon = require("../assets/postbox_msgType_Icons/forevermind_postbox_icon_" +
      this.msgType +
      ".png");
  },

  methods: {
    async getProfilePic() {
      this.blobImageUrl = await postboxAPI.getSenderProfilePic(
        this.msgData.Content_Data[0].content_ids
      );
    },
  },

  watch: {
    showMessageModal: {
      handler() {
        if (this.showMessageModal) {
          // add class to body to prevent scrolling
          document.body.classList.add("modal-open");
        } else {
          // remove class from body to enable scrolling
          document.body.classList.remove("modal-open");
        }
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/scss/_colors.scss";
@import "../assets/scss/_fonts.scss";
@import "../assets/scss/_typography.scss";
.card-container {
  min-height: 400px;
  max-width: 300px;
  min-width: 200px;
  border-radius: 35px;
  box-shadow: 0px 0px 20px $box-shadow-color;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}

.msg-type-icon {
  width: 80px;
  height: 80px;
  margin: 10px;
}

.msg-sender-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  flex-wrap: nowrap;
}

.sender-avatar {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.msg-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.sender-name {
  font-family: Futura EF Book;
  font-size: $body-text-font-size;
  color: $text-color;
  overflow-wrap: break-word;
  hyphens: manual;
  text-align: center;
}

.msg-title {
  font-family: Gilroy Extra Bold;
  font-size: $small-heading-font-size;
  color: $accent-color;
  overflow-wrap: break-word;
  hyphens: manual;
  text-align: center;
}

.do-not-open-label {
  font-size: $small-text-font-size;
  color: $menu-button-color;
  font-weight: bold;
  margin-bottom: 0;
}
</style>
