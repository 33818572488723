import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75e7d5f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "no-msgs-container"
}
const _hoisted_2 = {
  key: 1,
  class: "msg-card-list-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageCard = _resolveComponent("MessageCard")!
  const _component_ErrorStatusModal = _resolveComponent("ErrorStatusModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.noMessages)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("postbox.noMessagesLabel")), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messageList, (message) => {
            return (_openBlock(), _createElementBlock("div", {
              key: message.Content_Data[0].content_ids
            }, [
              _createVNode(_component_MessageCard, { msgData: message }, null, 8, ["msgData"])
            ]))
          }), 128))
        ])),
    (_ctx.showStatusModal)
      ? (_openBlock(), _createBlock(_component_ErrorStatusModal, {
          key: 2,
          title: _ctx.statusTitleText,
          message: _ctx.statusModalText,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showStatusModal = false))
        }, null, 8, ["title", "message"]))
      : _createCommentVNode("", true)
  ], 64))
}